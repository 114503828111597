import React from "react";
import { Dropdown } from "react-bootstrap";

const Categories = ({ categories, filterItems, activeCategory }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        style={{ width: "100%", Top: "5px" }}
        id="dropdown-basic"
      >
        Selecciona Categoria
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ backgroundColor: "#000000", width: "100%" }}>
        <div style={{ height: "auto", width: "100%", overflowX: "hidden" }}>
          {categories.map((category, index) => {
            return (
              <Dropdown.Item
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  textAlign: "center",
                  width: "100%",
                }}
                type="button"
                className={`${
                  activeCategory === category
                    ? "filter-btn active"
                    : "filter-btn"
                }`}
                key={index}
                onClick={() => filterItems(category)}
              >
                {category}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
      <hr style={{ marginBottom: "-5px" }} />
    </Dropdown>
  );
};

export default Categories;
