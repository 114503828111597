import React from "react";
// reactstrap components
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
// index sections
import SectionButtons from "views/index-sections/SectionButtons.js";
import SectionNavbars from "views/index-sections/SectionNavbars.js";
import SectionNavigation from "views/index-sections/SectionNavigation.js";
import SectionProgress from "views/index-sections/SectionProgress.js";
import SectionNotifications from "views/index-sections/SectionNotifications.js";
import SectionTypography from "views/index-sections/SectionTypography.js";
import SectionJavaScript from "views/index-sections/SectionJavaScript.js";
import SectionCarousel from "views/index-sections/SectionCarousel.js";
import SectionNucleoIcons from "views/index-sections/SectionNucleoIcons.js";
import SectionDark from "views/index-sections/SectionDark.js";
import SectionLogin from "views/index-sections/SectionLogin.js";
import SectionExamples from "views/index-sections/SectionExamples.js";
import SectionDownload from "views/index-sections/SectionDownload.js";
import SectionMaps from "./index-sections/sectionMaps";
import ViewMenu from "components/NewMenu/ViewMenu";
import Clip from "../assets/img/videos/Karaoke.mp4";
import Clip2 from "../assets/img/videos/Videobanner.mp4";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
        {/* <SectionButtons /> */}
        {/* <SectionNavbars /> */}
        {/* <SectionNavigation /> */}
        {/* <SectionProgress /> */}
        {/* <SectionNotifications /> */}
        {/* <SectionTypography />  */}
        {/* <SectionJavaScript /> */}
        
        <div
          style={{
            position: "relative",
            height: "auto",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            style={{ width: '100%', height: "auto", maxHeight: 'auto'}}
            className="video-player"
            preload="auto"
            autoPlay="autoplay"
            loop
            muted
            playsInline
          >
            <source src={Clip2} type="video/mp4" />
          </video>
        </div>

        <ViewMenu />
        <SectionCarousel />
        <SectionNucleoIcons />
        <SectionExamples />
        <SectionMaps />
        <SectionDownload />
        {/* <SectionLogin /> */}

        <SectionDark />

        <DemoFooter />
      </div>
    </>
  );
}

export default Index;
