import React from "react";
import { Button } from "react-bootstrap";
import { Container } from "reactstrap";
import Logo from "../../assets/img/prueba.png";
import ViewMenu from "components/NewMenu/ViewMenu";
import { ModalGenerico } from "../../components/GenericModal/ModalGenerico";

// core components
function IndexHeader() {
  const [modalShow, setModalShow] = React.useState(false);
  const menuCompleto = <ViewMenu />;

  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/brick-background.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <div>
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "500px",
                    marginTop: "-250px",
                    marginBottom: "-190px",
                  }}
                  src={Logo}
                />
              </div>

              {/* <h1 className="presentation-title">Bienvenido <br/> <span style={{color: 'orange'}}>Margalitas</span></h1> */}
              {/* <div className="fog-low">
                <img
                  alt="..."
                  src={require("assets/img/fog-low.png").default}
                /> 
              </div>
              <div className="fog-low right">
                <img
                  alt="..."
                  src={require("assets/img/fog-low.png").default}
                /> 
              </div> */}
            </div>
            <h1
              style={{ color: "white", fontSize: "20px", fontWeight: "bolder" }}
              className="presentation-subtitle text-center"
            >
              LA CATEDRAL DEL<span style={{ color: "orange" }}>KARAOKE</span>
            </h1>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clouds.png").default + ")",
          }}
        />
      </div>
    </>
  );
}

export default IndexHeader;
