
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionMaps() {
  return (
    <>
      <div className="section section-dark" style={{width: '100%', backgroundColor: 'black'}}>
        
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 style={{fontWeight: 'bold'}} className="title">UBICA NUESTRAS SUCURSALES</h3>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div style={{textAlign: 'center'}}>
                <h3 className="description" style={{ marginBottom: '20px', fontWeight: 'bolder' }}>Nezahualcoyotl, 4a. Avenida</h3>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.168743896083!2d-99.00047038565762!3d19.40511374658864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e3daf333ba97%3A0xa57306f0b9315fba!2sMargAlitas!5e0!3m2!1ses!2smx!4v1655062483270!5m2!1ses!2smx" style={{ border: '0', borderRadius: '20px', width: '100%', maxWidth: '550px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>

            </Col>
            <Col lg="6" md="12">
              <div style={{textAlign: 'center'}}>
                <h3 className="description" style={{ marginBottom: '20px', fontWeight: 'bolder' }}>Metropolitana</h3>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.5436287629796!2d-99.03334788565788!3d19.388903747101942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fd8a39799b41%3A0x756ba306cacabe51!2sMargalitas!5e0!3m2!1ses!2smx!4v1655062994275!5m2!1ses!2smx" style={{ border: '0', borderRadius: '20px', width: '100%', maxWidth: '550px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
          </Row>
          <br/>
          <br/>
          <br/>
        </Container>
      </div>
    </>
  );
}

export default SectionMaps;
