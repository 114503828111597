import React, { useState } from "react";
import Menu from "./Menu";
import Categories from "./Categories";
import items from "./data";
import logo from "../../assets/img/darling.png";
import './styleMenu.css';
import './styleMenuNuevo.css';

const allCategories = ["Todo", ...new Set(items.map((item) => item.category))];

const InfantilMenu = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("");
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "Todo") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  return (
    <main>
      <section>
        <Categories categories={categories} activeCategory={activeCategory} filterItems={filterItems} />
      </section>
      <section className="menu section-another">
        <Menu items={menuItems} />
      </section>
    </main>
  );
};

export default InfantilMenu;
