import React from 'react'
import { Button, Modal, ModalTitle, ModalBody, ModalFooter, Dropdown, DropdownButton, SplitButton } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import Clip from '../../assets/img/videos/Alimentos.mp4';
import ClipB from '../../assets/img/videos/Bebidas.mp4';
import ClipK from '../../assets/img/videos/Alimentoskids.mp4';
import ClipP from '../../assets/img/videos/Postres.mp4';

export const ModalGenerico = (props) => {
    const { Titulo, Menu, Body } = props;
    return (
        <Modal  {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" scrollable='true' animation='true' centered >
            <div style={{ position: 'absolute', height: 'auto', width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <video style={{ width: '100%', height: 'auto' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                    <source src={Clip} type="video/mp4" />
                </video>
            </div>
            <div>
                <ModalHeader>
                    <ModalTitle>
                        <p style={{ color: 'white' }}>Categorias</p>
                    </ModalTitle>
                </ModalHeader>
            </div>

            <ModalBody style={{ backgroundColor: '#000000', color: 'white' }} >
                <div style={{ height: 'auto', width: '100%', color: 'white' }}>
                    {Body}
                </div>
            </ModalBody>
            <ModalFooter style={{ backgroundColor: '#000000', color: 'white' }}>
                <DropdownButton size="sm" key='up' id='dropdown-button-drop-up' drop="up" variant="danger" title='Salsas'>
                    <div style={{ height: '400px', overflowX: 'hidden' }}>
                        <Dropdown.Item eventKey="1">AL AJI</Dropdown.Item>
                        <Dropdown.Item eventKey="2">ARANDANO</Dropdown.Item>
                        <Dropdown.Item eventKey="3">BBQ</Dropdown.Item>
                        <Dropdown.Item eventKey="4">BUFFALO</Dropdown.Item>
                        <Dropdown.Item eventKey="5">CHIPOCLUDA</Dropdown.Item>
                        <Dropdown.Item eventKey="6">ESPECIAL MARGALITAS</Dropdown.Item>
                        <Dropdown.Item eventKey="7">EXPLOSIVA</Dropdown.Item>
                        <Dropdown.Item eventKey="8">FRESONA</Dropdown.Item>
                        <Dropdown.Item eventKey="9">ITALIANA</Dropdown.Item>
                        <Dropdown.Item eventKey="10">MANGO HABANERO</Dropdown.Item>
                        <Dropdown.Item eventKey="11">MARACA MARACUYA</Dropdown.Item>
                        <Dropdown.Item eventKey="12">ORIENTAL</Dropdown.Item>
                        <Dropdown.Item eventKey="13">ORIGINAL</Dropdown.Item>
                        <Dropdown.Item eventKey="14">PIMIENTA Y LIMON</Dropdown.Item>
                        <Dropdown.Item eventKey="15">SOLAS</Dropdown.Item>
                        <Dropdown.Item eventKey="16">TAMARINDO</Dropdown.Item>
                        <Dropdown.Item eventKey="17">TROPICAL</Dropdown.Item>
                        <Dropdown.Item eventKey="18">ZARZAMORA</Dropdown.Item>
                    </div>
                </DropdownButton>
                <DropdownButton size="sm" key='up' id='dropdown-button-drop-up' drop="up" variant="warning" title='Aderezos'>
                    <Dropdown.Item eventKey="1">RANCH $15.00</Dropdown.Item>
                    <Dropdown.Item eventKey="2">BLUE CHEESE $15.00</Dropdown.Item>
                    <Dropdown.Item eventKey="3">HONEY MUSTARD $15.00</Dropdown.Item>
                </DropdownButton>
                <DropdownButton size="sm" key='up' id='dropdown-button-drop-up' drop="up" variant="primary" title='Extras'>
                    <Dropdown.Item eventKey="1">Envio $25.00</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Extra Salsa $12.00</Dropdown.Item>
                    <Dropdown.Item eventKey="3">Extra Queso $15.00</Dropdown.Item>
                </DropdownButton>
                <Button style={{ marginTop: '10px', backgroundColor: 'orange', border: 'orange 1px' }} onClick={props.onHide}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export const ModalGenericoBebidas = (props) => {
    const { Titulo, Menu, Body } = props;
    return (
        <Modal  {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" scrollable='true' animation='true' centered >
            <div style={{ position: 'absolute', height: 'auto', width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <video style={{ width: '100%', height: 'auto' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                    <source src={ClipB} type="video/mp4" />
                </video>
            </div>
            <ModalHeader>
                <ModalTitle>
                    <p style={{ color: 'white' }}>Categorias</p>
                </ModalTitle>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: '#000000', color: 'white' }} >
                <div style={{ height: 'auto', width: '100%', color: 'white' }}>
                    {Body}
                </div>
            </ModalBody>
            <ModalFooter style={{ backgroundColor: '#000000', color: 'white' }}>
                <DropdownButton size="sm" key='up' id='dropdown-button-drop-up' drop="up" variant="danger" title='Servicios Cerveza'>
                    <div style={{ height: '400px', overflowX: 'hidden' }}>
                        <Dropdown.Item eventKey="1">MICHELADO - $10.00</Dropdown.Item>
                        <Dropdown.Item eventKey="2">CUBANO - $14.00</Dropdown.Item>
                        <Dropdown.Item eventKey="3">VAMPIRO LT - $20.00</Dropdown.Item>
                        <Dropdown.Item eventKey="4">MICHELADO LT - $14.00</Dropdown.Item>
                        <Dropdown.Item eventKey="5">CUBANO LT - $16.00</Dropdown.Item>
                        <Dropdown.Item eventKey="6">VAMPIRO - $18.00</Dropdown.Item>
                        <Dropdown.Item eventKey="7">TINTO LT - $20.00</Dropdown.Item>
                        <Dropdown.Item eventKey="8">BANDERA TEQUILA - $39</Dropdown.Item>
                        <Dropdown.Item eventKey="9">CUBANO B/A - $14.00</Dropdown.Item>
                        <Dropdown.Item eventKey="10">DIABLO B/A - $22.00</Dropdown.Item>
                        <Dropdown.Item eventKey="11">DIABLO B/A LT - $24.00</Dropdown.Item>
                        <Dropdown.Item eventKey="12">MICHELADO B/A - $10.00</Dropdown.Item>
                        <Dropdown.Item eventKey="13">MICHELADO B/A LT - $14.00</Dropdown.Item>
                        <Dropdown.Item eventKey="14">TINTO B/A - $18.00</Dropdown.Item>
                        <Dropdown.Item eventKey="15">TINTO B/A LT - $20.00</Dropdown.Item>
                        <Dropdown.Item eventKey="16">VAMPIRO B/A - $18.00</Dropdown.Item>
                        <Dropdown.Item eventKey="16">VAMPIRO B/A LT - $20.00</Dropdown.Item>
                        <Dropdown.Item eventKey="16">CUBANO B/A LT - $16.00</Dropdown.Item>
                        <Dropdown.Item eventKey="17">SOLO B/A</Dropdown.Item>
                        <Dropdown.Item eventKey="18">SOLO</Dropdown.Item>
                    </div>
                </DropdownButton>
                <Button style={{ marginTop: '10px', backgroundColor: 'orange', border: 'orange 1px' }} onClick={props.onHide}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export const ModalGenericoCocteles = (props) => {
    const { Titulo, Menu, Body } = props;
    return (
        <Modal  {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" scrollable='true' animation='true' centered >
            <div style={{ position: 'absolute', height: 'auto', width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <video style={{ width: '100%', height: 'auto' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                    <source src={ClipP} type="video/mp4" />
                </video>
            </div>
            <ModalHeader>
                <ModalTitle>
                    <p style={{ color: 'white' }}>Categorias</p>
                </ModalTitle>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: '#000000', color: 'white' }} >
                <div style={{ height: 'auto', width: '100%', color: 'white' }}>
                    {Body}
                </div>
            </ModalBody>
            <ModalFooter style={{ backgroundColor: '#000000', color: 'white' }}>
                <Button style={{ backgroundColor: 'orange', border: 'orange 2px' }} onClick={props.onHide}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export const ModalGenericoInfantil = (props) => {
    const { Titulo, Menu, Body } = props;
    return (
        <Modal  {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" scrollable='true' animation='true' centered >
            <div style={{ position: 'absolute', height: 'auto', width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <video style={{ width: '100%', height: 'auto' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
                    <source src={ClipK} type="video/mp4" />
                </video>
            </div>
            <ModalHeader>
                <ModalTitle>
                    <p style={{ color: 'white' }}>Categorias</p>
                </ModalTitle>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: '#000000', color: '#000000' }} >
                <div style={{ height: 'auto', width: '100%', color: '#000000' }}>
                    {Body}
                </div>
            </ModalBody>
            <ModalFooter style={{ backgroundColor: '#000000', color: 'white' }}>
                <Button style={{ backgroundColor: 'orange', border: 'orange 2px' }} onClick={props.onHide}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}