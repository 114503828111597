import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

function SectionExamples() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row className="example-page">
            <Col>
              <div style={{ width: "100%" }}>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/examples/first.png").default}
                  style={{ padding: "auto auto auto auto" }}
                />
              </div>
            </Col>

            <Col>
              <div style={{ width: "100%" }}>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/examples/second.png").default}
                  style={{ padding: "auto auto auto auto" }}
                />
              </div>
            </Col>
          </Row>
          <div style={{ textAlign: "center" }}>
            <Button className="btn-outline-neutral btn-round" color="default">
              Conoce nuestro menú de bebidas que tenemos para ti
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
